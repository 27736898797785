import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Select, Radio, Checkbox } from "antd";

import { getLocationList, getareaById } from "../../api/list";

import PageSpinner from "../../components/PageSpinner";
import PageError from "../../components/PageError";
import RoundedButton from "../../components/TuorPanel/Button";

import rentalGraphic from "../../assets/images/rental-graphic-main.png";


const TypeOptions = [
  { label: "House or Flat", value: "flat" },
  { label: "Hostel or PG", value: "hostel" },
  { label: "Flatmates", value: "flatmate" },
];

const Preferences = {
  accommodation: {
    flat: [
      { label: "Single Occupancy", value: "single_occupancy" },
      { label: "Double Sharing", value: "sharing_double" },
      { label: "Triple Sharing", value: "sharing_triple" },
      { label: "Sharing with 4 or more", value: "sharing_multi" },
    ],
    hostel: [
      { label: "Single Occupancy", value: "single_occupancy" },
      { label: "Double Sharing", value: "sharing_double" },
      { label: "Triple Sharing", value: "sharing_triple" },
      { label: "Sharing with 4 or more", value: "sharing_multi" },
    ]
  },
  budget: {
    flat: [
      { label: "Less than 2000", value: "under_2k" },
      { label: "2000 to  3000", value: "2k_3k" },
      { label: "3000 to 5000", value: "3k_5k" },
      { label: "5000 and above", value: "above_5k" },
    ],
    hostel: [
      { label: "Less than 2000", value: "under_2k" },
      { label: "2000 to  3000", value: "2k_3k" },
      { label: "3000 to 5000", value: "3k_5k" },
      { label: "5000 and above", value: "above_5k" },
    ]
  },
  amenities: {
    flat: [
      { label: "Wi-Fi", value: "wifi" },
      { label: "Bed", value: "bed" },
      { label: "Table & Chair", value: "table_chair" },
      { label: "Meals", value: "meals" },
    ],
    hostel: [
      { label: "Wi-Fi", value: "wifi" },
      { label: "Bed", value: "bed" },
      { label: "Table & Chair", value: "table_chair" },
      { label: "Meals", value: "meals" },
    ]
  },
}

const Rental: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageError, setPageError] = useState<string|null>(null);
  const cityList = useRef<any>([]);

  const [values, setValues] = useState<any>({});

  const setFieldValue = (name: string, value: string) => {
    setValues(Object.assign({}, values, { [name]: value }));
  };
  const handleFieldChange: (e: any) => void = ({ target: { name, value } }) => {
    setFieldValue(name, value);
  };

  const handleTypeChange: (e: any) => void = ({ target: { value } }) => {
    setFieldValue("type", value);
  };

  const handleSearch = () => {
    // navigate("/rental-listing");
  };

  const fetchCities = async () => {
    const res = await getLocationList();
		if (!res.success) {
			setPageError("Failed to fetch Cities.");
			setIsLoading(false);
			return false;
		}
    const list = _.chain(res.records)
      .map((item: any) => ({ id: item.id, label: item.location, value: item.location }))
      .sortBy(["label"])
      .value();
		cityList.current = list;
		return cityList;
  };

  const initialize = async () => {
		await fetchCities() &&
		setIsLoading(false);
	};

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    setFieldValue("area", "");
  }, [values.city]);

  return (
    <div className="rental-home-container">
      {(isLoading && <PageSpinner />) ||
      (pageError && <PageError title={pageError} />) ||
      (
        <div className="primary-content">
          <h1 className="rental-heading">Find Flats, Hostels, PGs or Flatmates</h1>

          <Row>
            <Col span={14}>
              <div className="interaction-container">
                <div id="section-one">
                  <h3 className="section-title">Select your location</h3>

                  <Row gutter={30}>
                    <Col span={12}>
                      <Input
                        name="city"
                        placeholder="Select City *"
                        value={values.city || ""}
                        options={cityList.current}
                        onSelect={handleFieldChange}
                      />
                    </Col>

                    <Col span={12}>
                      <SelectArea
                        city={values.city}
                        cityList={cityList.current}
                        value={values.area || ""}
                        onSelect={handleFieldChange}
                      />
                    </Col>
                  </Row>
                </div>

                {!!values.city && <div id="section-two">
                  <h3 className="section-title">Choose one</h3>

                  <Radio.Group block
                    optionType="button"
                    buttonStyle="solid"
                    size="large"
                    onChange={handleTypeChange}
                    options={TypeOptions}
                  />
                </div>}

                {!!values.type && <div id="section-three">
                  <h3 className="section-title">Select your preferences</h3>

                  <div className="pref-container">
                    <Row gutter={20}>
                      <Col span={8}>
                        <h4 className="pref-title">Accommodation Type</h4>

                        {_.map(Preferences.accommodation[values.type as keyof Object], (item: any) => (
                          <div className="pref-checkbox-field">
                            <Checkbox className="pref-checkbox" />
                            <span className="pref-checkbox-label">{item.label}</span>
                          </div>
                        ))}
                      </Col>

                      <Col span={8}>
                        <h4 className="pref-title">Budget (₹)</h4>

                        {_.map(Preferences.budget[values.type as keyof Object], (item: any) => (
                          <div className="pref-checkbox-field">
                            <Checkbox className="pref-checkbox" />
                            <span className="pref-checkbox-label">{item.label}</span>
                          </div>
                        ))}
                      </Col>

                      <Col span={8}>
                        <h4 className="pref-title">Amenities</h4>

                        {_.map(Preferences.amenities[values.type as keyof Object], (item: any) => (
                          <div className="pref-checkbox-field">
                            <Checkbox className="pref-checkbox" />
                            <span className="pref-checkbox-label">{item.label}</span>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </div>
                </div>}

                {!!values.type && <div className="actions-wrapper">
                  <RoundedButton variant="secondary" width="30%">
                    Clear
                  </RoundedButton>

                  <RoundedButton variant="primary" width="30%" onClick={handleSearch}>
                    Search
                  </RoundedButton>
                </div>}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

type InputProps = {
  name: string
  placeholder: string
  value?: string
  error?: string
  options: {
    label?: string
    value: string
  }[]
  disabled?: boolean
  loading?: boolean
  onSelect: (event: any) => void
  onBlur?: React.FocusEventHandler<HTMLElement>
}
const Input: React.FC<InputProps> = (
  { name, placeholder, value, error, options, disabled, loading, onSelect, onBlur }
) => {
  const handleSelect = (value: string) => onSelect({ target: { name, value: value || "" } });

  return (
    <Form.Item
      validateStatus={error && "error"}
      help={error}
    >
      <Select
        variant="filled"
        size="large"
        showSearch allowClear
        optionFilterProp="label"
        disabled={disabled}
        loading={loading}
        style={{ width: "100%" }}
        options={options}
        placeholder={placeholder}
        value={value || (_.isString(value) ? null : undefined)}
        onChange={handleSelect}
        onBlur={onBlur}
        filterOption={(inputValue, option) => {
          return option?.label?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }}
      />
    </Form.Item>
  );
};

const SelectArea: React.FC<any> = ({ city, cityList, value, onSelect, onBlur, error }) => {
  const [areaList, setAreaList] = useState<any[]|null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if(!city) {
      setAreaList(null);
      return;
    }

    setIsLoading(true);
    const data = _.find(cityList, c => c.value === city);
    getareaById(data.id).then((res) => {
      if (res?.records) {
        const areaData = _.chain(res.records)
          .map((item: any) => ({ label: item.area, value: item.area }))
          .sortBy(["label"])
          .value();
          setAreaList(areaData);
          setIsLoading(false);
      }
    });
  }, [city, cityList]);

  return (
    <Input
      name="area"
      placeholder="Select Area"
      value={value}
      disabled={!areaList}
      loading={isLoading}
      error={error}
      options={areaList || []}
      onSelect={onSelect}
      onBlur={onBlur}
    />
  );
};

export default Rental;