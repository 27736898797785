import React from "react";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./serviceStyle.scss";
import Rental from "./Rental";

const Services: React.FC = () => {
  return (
    <div className="bg-white">
      <Header />

      <Rental />
      
      <Footer />
    </div>
  );
};

export default Services;
