import styled from "styled-components";

import DownArrow from "../../assets/images/arrow-down.svg";

export const MainContainer = styled.div`
  height: 100%;
  overflow: auto;
`;


// Profile Summary ===================================
export const SummaryContainer = styled.div`
  padding: 80px 0px;
  @media (max-width: 1400px) {
    padding: 70px 0px 80px;
  }
  @media (max-width: 1199px) {
    padding: 50px 0px 80px;
  }
  @media (max-width: 991px) {
    padding: 40px 0px 80px;
  }
`;

export const SummaryHeader = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const SummaryLogo = styled.div`
  height: 100px;
  width: 100px;
  min-width: 100px;
  margin-right: 30px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border: 4px solid #ffffff;
    border-radius: 50px;
  }
`;

export const HeaderContent = styled.div`
  flex-grow: 1;
`;

export const HeaderTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #940E34;
  margin-top: 10px;
`;

export const HeaderDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const DetailItem = styled.div`
  margin-top: 20px;
  span, a {
    margin-left: 10px;
    color: #012834;
    opacity: 0.5;
  }
  a:hover, a: active {
    opacity: 1;
  }
`;

export const Line = styled.div`
  height: 26px;
  width: 1px;
  margin: 20px 30px 0;
  background-color: #707070;
  @media (max-width: 900px) {
    display: none;
  }
`;

export const SummaryDescription = styled.div`
  margin-top: 30px;
  font-size: 14px;
  color: #012834;
  opacity: 0.8;
`;

export const SummaryCards = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
`;

export const SummaryCard = styled.div`
  flex-grow: 1;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 20px;

  .icon {
    font-size: 18px;
    color: #940e34;
    padding: 10px;
    border: 1px solid #940e34;
    border-radius: 20px;
  }
`;

export const VideoCard = styled.div`
  flex-grow: 0;
  position: relative;

  .video-thumb {
    height: 143px;
    cursor: pointer;
    border-radius: 20px;
  }
  .video-thumb-overlay {
    position: absolute;
    height: 143px;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 20px;
    background-color: rgba(0,0,0,0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: rgba(0,0,0,0.2);
    }

    span {
      color: #ffffff;
      font-size: 36px;
    }
  }
`;

export const CardImage = styled.img`
  height: 40px;
  width: 40px;
  padding: 10px;
  border: 1px solid #940e34;
  border-radius: 20px;
`;

export const CardTitle = styled.div`
  margin-top: 12px;
  font-size: 14px;
  color: #940E34;
`;

export const CardInfo = styled.div`
  margin-top: 8px;
  font-size: 16px;
  color: #012834;
`;


// Profile Subjects ===================================
export const SubjectsContainer = styled.div`
  padding: 80px 0;
  background-color: #ffffff;

  .content-container {
    padding-left: 28px;
    @media (max-width: 900px) {
      padding-left: 12px;
    }
  }
`;

export const ListHeader = styled.h3`
  margin-bottom: 40px;
  font-weight: bold;
`;

export const SubjectsList = styled.div`
  margin-bottom: 40px;
  border-right: 4px solid #940e34;
`;

export const SubjectButton = styled.div`
  padding: 30px 20px;
  margin-left: -20px;
  font-size: 20px;
  color: #012834;
  font-weight: 600;
  cursor: pointer;

  @media (max-width: 900px) {
    padding: 15px 20px;
  }

  &.active {
    background-color: rgba(0,0,0,0.03);
    border-right: 4px solid #940e34;
  }
  &:hover {
    background-color: rgba(0,0,0,0.05);
  }
  &:active {
    background-color: rgba(0,0,0,0.07);
  }
`;

export const BatchesList = styled.div``;

export const BatchContainer = styled.div`
  margin-bottom: 30px;
  padding: 30px 0 0;
  border-radius: 20px;
  background-color: #F7F7F7;
`;

export const FieldsContainer = styled.div`
  padding: 0 40px 30px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 40px;
`;

export const BatchField = styled.div`
  
`;

export const BatchFieldLabel = styled.div`
  font-size: 14px;
  color: #940E34;
`;

export const BatchFieldValue = styled.div`
  margin-top: 8px;
  font-size: 20px;
  font-weight: 600;
  color: #012834;
`;

export const ActionContainer = styled.div`
  border-top: 1px solid rgba(0,0,0,0.2);
  background-color: rgba(0,0,0,0.03);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 15px 15px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  .btn {
    font-size: 14px;
    font-weight: 600;
    padding: 10px 30px;
    border-radius: 20px;

    &:hover {
      background: #820c2e;
      color: #ffffff;
    }
    &:active {
      background: #940e34;
      color: #ffffff;
      box-shadow: none;
    }
  }
`;


// Modal Book Batch ===================================
export const ModalContentTitle = styled.div`
  font-size: 24px;
  line-height: 125%;
  font-weight: 800;
  margin-bottom: 20px;
  @media (max-width: 1440px) {
    font-size: 22px;
    margin-bottom: 10px;
  }
  @media (max-width: 1366px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

export const BookBatchBody = styled.div`
  display: flex;
  
  @media (max-width: 900px) {
    display: block;
  }
`;

export const BookBatchInfo = styled.div`
  flex-grow: 1;
  padding-right: 30px;

  & > div {
    margin-bottom: 30px;
  }
`;

export const BookBatchAction = styled.div`
  min-width: 300px;
`;

export const CalendarWrapper = styled.div`
  .react-calendar__navigation__prev2-button {
    display: none;
  }
  .react-calendar__navigation__next2-button {
    display: none;
  }
  .react-calendar {
    background: #e6e6e6;
    border: 1px solid #c4c4c459;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    position: relative;
  }
  .react-calendar__tile--now {
    border: 1px solid #940e34 !important;
    color: #940e34;
    background: #e6e6e6;
    &:hover {
      background: #e6e6e6;
    }
  }
  .react-calendar__tile--rangeStart,
  .react-calendar__tile--rangeEnd {
    background: #940e34 !important;
    color: #ffffff !important;
  }
  .react-calendar__tile--active {
    background: #e6e6e6;
    color: #000000;
    &:hover {
      background: #e6e6e6;
    }
    &:focus {
      background: #e6e6e6;
    }
  }
  .react-calendar__navigation__prev-button {
    position: absolute;
    left: 20px;
    top: 20px;
    font-size: 0px;
    &::before {
      background-image: url(${DownArrow});
      background-repeat: no-repeat;
      padding: 0;
      width: 15px;
      height: 8px;
      -webkit-transform: rotate(85deg);
      -ms-transform: rotate(85deg);
      transform: rotate(85deg);
      content: "";
      display: inline-block;
      /* position: absolute; */
    }
  }
  .react-calendar__month-view__days__day--weekend {
    color: #000000;
    &:disabled {
      color: rgba(16, 16, 16, 0.3);
    }
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: rgba(16, 16, 16, 0.3);
  }
  .react-calendar__navigation button {
    min-width: 15px;
    background: none;
  }
  .react-calendar__month-view__weekdays__weekday {
    border: 1px solid #c4c4c459;
    font-size: 19px;
    line-height: 21px;
    -webkit-text-decoration: none;
    text-decoration: none;
    height: 48px;
    display: flex;
    align-items: center;
    @media (max-width: 1440px) {
      height: 40px;
    }
    abbr[title] {
      text-decoration: none;
      cursor: help;
      text-decoration-skip-ink: none;
      font-size: 18px;
      width: 18px;
      height: 16px;
      margin: 0 auto;
      overflow: hidden;
      display: block;
      color: #000000;
      font-weight: 500;
    }
  }
  .react-calendar__tile {
    font-size: 19px;
    line-height: 21px;
    border: 1px solid #c4c4c459;
    @media (max-width: 1440px) {
      font-size: 17px;
      line-height: 19px;
    }
    @media (max-width: 370px) {
      font-size: 10px;
      line-height: 19px;
    }
  }
  .react-calendar__navigation {
    display: flex;
    height: 48px;
    margin-bottom: 0;
    align-items: center;
    @media (max-width: 1440px) {
      height: 40px;
    }
  }
  .react-calendar__navigation__label {
    padding: 13px 20px;
    .react-calendar__navigation__label__labelText {
      font-size: 20px;
      font-weight: 700;
      display: flex;
      justify-content:center;   
      @media (max-width: 1440px) {
        font-size: 18px;
      }
      @media (max-width: 1440px) {
        font-size: 17px;
      }
    }
  }
  .react-calendar__navigation__next-button {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 0px;
    @media (max-width: 1440px) {
      right: 15px;
      top: 15px;
    }
    @media (max-width: 767px) {
      right: 15px;
      top: 20px;
    }
    &::before {
      background-image: url(${DownArrow});
      background-repeat: no-repeat;
      padding: 0;
      width: 15px;
      height: 8px;
      -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: rotate(270deg);
      content: "";
      display: inline-block;

      /* position: absolute; */
    }
  }
`;

export const BookNowButton = styled.div`
  margin-top: 30px;

  .btn {
    width: 180px;
    font-weight: 500;
    &:disabled {
      background: #6c757d;
      border-color: #6c757d;
    }
  }
`;