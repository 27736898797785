import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Tooltip, Radio, Slider, Checkbox } from "antd";
import { SearchOutlined, EnvironmentOutlined, CalendarOutlined, HomeOutlined } from '@ant-design/icons';

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import "./style.scss";


const RentalListing: React.FC = () => {
  return (
    <div className="rental-listing-page">
      <Header />

      <div className="rental-listing-container">
        <h2 style={{ marginBottom: 30 }}>Available Listings</h2>
        <Row gutter={30}>
          <Col span={9}>
            <div className="controls-container">
              <div className="controls-header">
                <div className="control-items">
                  <HeaderItem Icon={EnvironmentOutlined} text="Patna" />
                  <HeaderDivider />
                  <HeaderItem Icon={CalendarOutlined} text="Date" />
                  <HeaderDivider />
                  <HeaderItem Icon={HomeOutlined} text="House or Flat" />
                </div>

                <div className="rental-search">
                  <Tooltip title="Search">
                    <Button type="primary" shape="circle" icon={<SearchOutlined />} />
                  </Tooltip>
                </div>
              </div>

              <div className="controls-body">
                <h5>Filters</h5>

                <div className="control-title">BHK Type</div>
                <div style={{ marginTop: 16 }}>
                  <Radio.Group>
                    <Radio value={1}>1 RK</Radio>
                    <Radio value={2}>1 BHK</Radio>
                    <Radio value={3}>2 BHK</Radio>
                    <Radio value={4}>3 BHK</Radio>
                    <Radio value={5}>3+ BHK</Radio>
                  </Radio.Group>
                </div>

                <div className="control-title">Rent Range</div>
                <div style={{ marginTop: 16 }}>
                  <Slider range max={50000} defaultValue={[0, 10000]} />
                </div>

                <div className="control-title">Preferred Tenants</div>
                <div>
                  <Checkbox.Group options={["Family", "Bachelor Male", "Bachelor Female"]} />
                </div>
              </div>
            </div>
          </Col>

          <Col span={15}>
            <div className="listing-container"></div>
          </Col>
        </Row>
      </div>

      <Footer />
    </div>
  );
};

const HeaderItem: React.FC<any> = ({ Icon, text }) => {
  return (
    <div>
      <Icon /> &nbsp; {text}
    </div>
  );
};
const HeaderDivider: React.FC = () => {
  return <div style={{ height: 20, width: 1, background: "#000" }} />;
};

export default RentalListing;