import React, { useState, useEffect } from "react";
import moment from "moment";
import Calendar from "react-calendar";
import { Button } from "react-bootstrap";
import { Alert } from 'antd';
import { SyncOutlined } from "@ant-design/icons";

import "react-calendar/dist/Calendar.css";

import { getBookingValidation } from "../../api/list";
import { CreateBookingDemoClass, CreateBookingSeat } from "../../redux/action";
import { CreateBookingPayment, VerifyBookingPayment } from "../../redux/action/paymentAction";

import BaseModal from "../../components/Modal/BaseModal";
import PageSpinner from "../../components/PageSpinner";
import PageError from "../../components/PageError";

import {
  ModalContentTitle,
  BookBatchBody,
  BookBatchInfo,
  BatchField,
  BatchFieldLabel,
  BatchFieldValue,
  BookBatchAction,
  CalendarWrapper,
  BookNowButton
} from "./styledViews";


declare global {
  interface Window {
    Razorpay: any
  }
}

type Props = {
  show: boolean
  onHide: () => void
  isDemo: boolean
  user: any
  teacherData: any
  subject: string
  batch: any
  timing: string
}

const ModalBookBatch: React.FC<Props> = ({ show, onHide, isDemo, user, teacherData, subject, batch, timing }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isBookingLoading, setIsBookingLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<string|boolean>(false);
  const [bookingError, setBookingError] = useState<string|boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [date, setDate] = useState<string>(moment(new Date()).add(1, 'd').format("YYYY-MM-DD"));

  useEffect(() => {
    let params = {
      teacher_id: Number(teacherData.id),
      subject: subject,
      student_id: Number(user.id),
    };

    getBookingValidation(params).then((res) => {
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
      setShowError("Unable to place booking.");
    });
  }, []);

  const handleChangeDate = (value: any) => {
    if (isBookingLoading || showSuccess) return;
    setDate(moment(value).format("YYYY-MM-DD"));
  };

  const handleBooking = () => {
    setBookingError(false);
    setIsBookingLoading(true);

    if (isDemo) handleBookingDemo();
    else handleBookingSeat();
  };

  const handleBookingDemo = () => {
    const payload = {
      batch_id: Number(batch.batch_id),
      student_id: Number(user.id),
      teacher_id: Number(teacherData.id),
      date
    };
    
    CreateBookingDemoClass(payload).then(res => {
      setIsBookingLoading(false);
      setShowSuccess(true);
    }).catch(err => {
      setBookingError(err.message);
      setIsBookingLoading(false);
    });
  };

  const handleBookingSeat = async () => {
    const paymentData: any = await initiatePayment();
    if (paymentData.error) {
      setBookingError(paymentData.error);
      setIsBookingLoading(false);
      return;
    }

    const scriptAdded: any = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!scriptAdded) {
      setBookingError("Failed to initiate Payment");
      setIsBookingLoading(false);
      return;
    }

    const rzpResponse: any = await showRazorpay(paymentData);
    if (!rzpResponse?.razorpay_order_id) {
      setBookingError("Payment Failed");
      setIsBookingLoading(false);
      return;
    }

    const verifyRes: any = await verifyPayment(rzpResponse, paymentData.paymentId);
    if (verifyRes.error) {
      setBookingError(verifyRes.error);
      setIsBookingLoading(false);
      return;
    }

    createBookingSeat();
  };

  const initiatePayment = () => (new Promise((resolve) => {
    const payload = {
      amount: "20000",
      batch_id: batch.batch_id,
      student_id: Number(user.id),
      teacher_id: Number(teacherData.id),
      payment_type: "card",
    };

    CreateBookingPayment(payload).then(res => {
      if (res.success && res?.records?.id) resolve({ paymentId: res.records.id, orderId: res.order_details.id });
      else resolve({ error: res.error || res.message || "Failed to initiate Payment" });
    });
  }));

  function loadScript(src: string) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const showRazorpay = (paymentData: any) => {
    return new Promise((resolve) => {
      const payload = {
        key: "rzp_live_3QZjSVkZnWpqkN",
        amount: "20000",
        currency: "INR",
        name: "Tuition Mitra",
        description: "Reserve a Seat",
        order_id: paymentData.orderId,
        theme: { color: "#3B5971" },
        handler: resolve
      };

      const portal = new window.Razorpay(payload);
      portal.open();
    });
  };

  const verifyPayment = (rzpRes: any, paymentId: number) => {
    return new Promise((resolve) => {
      const payload = {
        payment_id: paymentId,
        razorpay_order_id: rzpRes.razorpay_order_id,
        razorpay_payment_id: rzpRes.razorpay_payment_id,
        razorpay_signature: rzpRes.razorpay_signature
      };

      VerifyBookingPayment(payload).then(res => {
        if (res.success) resolve(res);
        else resolve({ error: res.error || res.message || "Failed to verify Payment" });
      });
    });
  };

  const createBookingSeat = () => {
    const payload = {
      batch_id: Number(batch.batch_id),
      student_id: Number(user.id),
      teacher_id: Number(teacherData.id),
      is_demo: false,
      date
    };

    CreateBookingSeat(payload).then(res => {
      setIsBookingLoading(false);
      setShowSuccess(true);
    }).catch(err => {
      setBookingError(err.message);
      setIsBookingLoading(false);
    });
  };

  return (
    <BaseModal show={show} onHide={onHide} title={isDemo ? "Book a Demo Class" : "Book a Seat"}>
      {(isLoading && <PageSpinner height={300} />) ||
      (showError && <PageError title={String(showError)} />) ||
      (<BookBatchBody>
        <BookBatchInfo>
          <BatchField>
            <BatchFieldLabel>Subject</BatchFieldLabel>
            <BatchFieldValue>{subject}</BatchFieldValue>
          </BatchField>

          <BatchField>
            <BatchFieldLabel>Timing</BatchFieldLabel>
            <BatchFieldValue>{timing}</BatchFieldValue>
          </BatchField>

          <BatchField>
            <BatchFieldLabel>Class</BatchFieldLabel>
            <BatchFieldValue>{batch.level}</BatchFieldValue>
          </BatchField>

          <BatchField>
            <BatchFieldLabel>Available Seats</BatchFieldLabel>
            <BatchFieldValue>{batch.seats_remaining}</BatchFieldValue>
          </BatchField>
        </BookBatchInfo>

        <BookBatchAction>
          {isDemo && <>
            <ModalContentTitle>Pick a Date</ModalContentTitle>

            <CalendarWrapper>
              <Calendar
                className={["c1", "c2"]}
                onChange={handleChangeDate}
                minDate={new Date()}
                value={moment(date).toDate()}
              />
            </CalendarWrapper>
          </>}

          {!showSuccess && <BookNowButton>
            <Button
              onClick={handleBooking}
              disabled={isBookingLoading}
            >
              {isBookingLoading ? <SyncOutlined spin /> : "Book"}
            </Button>
          </BookNowButton>}

          {bookingError && <Alert
            style={{ marginTop: 30 }}
            type="error"
            showIcon
            message={bookingError}
          />}

          {showSuccess && <Alert
            style={{ marginTop: 30 }}
            type="success"
            showIcon
            message="Booking Successful"
          />}
        </BookBatchAction>
      </BookBatchBody>)}
    </BaseModal>
  );
};

export default ModalBookBatch;