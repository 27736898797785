import "./App.css";
import PublicRoutes from "../routes/publicRoute";
import { Routes, Route } from "react-router-dom";
// import Login from "../pages/auth/login";
import Auth from "../pages/Auth";
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";
import Home from "../pages/home";
import TuitionList from "../pages/tuitionList/index";
import AboutUs from "../pages/AboutUs";
import Blogs from "../pages/Blog";
// import TuitionDetails from "../pages/tuitionDetails";
import TuitionProfile from "../pages/tuitionProfile";
import BlogDetails from "../pages/blogDetails";

// import Register from "../pages/auth/register";
import SearchResult from "../pages/searchResult";
import PaymentTesting from "../components/TuitionDetails/testPayment";
import Layout from "../components/TuorPanel/Layout";
import React, { useEffect } from "react";
import Batches from "../pages/tutorPanel/Batches";
import StudyMaterials from "../pages/tutorPanel/StudyMaterials";
import NoRecords from "../pages/tutorPanel/NoRecord";
import StudentInfo from "../pages/tutorPanel/studentInfo";
import Reviews from "../pages/tutorPanel/Reviews";
import Profile from "../pages/tutorPanel/Profile";
import StudentProfile from "../pages/tutorPanel/Profile/studentProfile";
import Post from "../pages/tutorPanel/Post";
import { Cookies } from "react-cookie";
import Services from "../pages/Services/index";
import RentalListing from "../pages/Services/RentalListing";
import TcStudent from "../pages/tcStudents";
import TcTutors from "../pages/tcTutors";
import CookiePolicy from "../pages/cookiesPolicy";
import CookieSetting from "../pages/cookieSettings";
import PrivacyPolicy from "../pages/privacyPolicy";
import Dashboard from "../pages/tutorPanel/dashboard";
// import MyBookings from "../pages/MyBookings";
import MyClasses from "../pages/MyClasses";
import ClassContent from "../pages/ClassContent";
import EmailVerification from "../pages/EmailVerification";
import IdleTimer from "../utils/idleTimer";
import AvailableServices from "../pages/availableService";
import PostYourService from "../pages/availableService/postYourService";
import { idleTime } from "../utils/dateFormat";
import PropertyDetails from "../pages/availableService/propertyDetails";
import RefundPolicy from "../pages/refundPolicy/policy";
import VideoViewer from "../pages/ClassContent/VideoViewer";


const App: Function = () => {
  const cookies = new Cookies();

  const userData = cookies.get("TeacherloggedUser");
  const storedData = localStorage.getItem("loggedUsers");
  let loggedUser = {};

  try {
    loggedUser = storedData ? JSON.parse(storedData) : {};
  } catch (error) {
    console.error("Error parsing JSON:", error);
    // You can handle the error here, e.g., provide a default value for loggedUser
  }

  useEffect(() => {
    if (loggedUser || userData) {
      const timer = new IdleTimer({
        timeout: idleTime,
        onTimeout: async () => {
          localStorage.clear();
          window.location.href = "/";
          cookies.remove("TeacherloggedUser", { path: "/" });
        },
        onExpired: () => {
          localStorage.clear();
          cookies.remove("TeacherloggedUser", { path: "/" });
        },
      });
      return () => {
        timer.cleanUp();
      };
    }
  }, []);
  return (
    <>
      {userData !== undefined ? (
        <Layout>
          <Routes>
            {/* <Route path="/tutorpanel" element={} /> */}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/post" element={<Post />} />
            <Route path="/student-info" element={<StudentInfo />} />
            <Route path="/batches" element={<Batches />} />
            <Route path="/batches/:batchId/study-materials" element={<StudyMaterials />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="*" element={<Dashboard />} />
            <Route path="/verify" element={<EmailVerification />} />
          </Routes>
        </Layout>
      ) : loggedUser ? (
        <Routes>
          <Route path="/auth" element={<PublicRoutes />}>
            <Route path="/auth" element={<Auth />}>
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
            </Route>
          </Route>
          {/* <Route path="/register" element={<PublicRoutes />}>
            <Route path="/register" element={<Register />} />
          </Route> */}
          <Route path="/" element={<Home />} />
          <Route path="/view-service" element={<AvailableServices />} />
          {/* <Route path="/post-service" element={<PostYourService />} />
          <Route path="/property-details" element={<PropertyDetails />} /> */}
          <Route path="/tuition-list" element={<TuitionList />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/blogs" element={<Blogs />} />

          <Route path="/services" element={<Services />} />
          <Route path="/rental-listing" element={<RentalListing />} />

          <Route path="/tuition-details" element={<TuitionProfile />} />
          <Route path="/blogs-details" element={<BlogDetails />} />
          <Route path="/search-result" element={<SearchResult />} />
          <Route path="/payment-testing" element={<PaymentTesting />} />
          <Route path="/student-profile" element={<StudentProfile />} />
          <Route path="/my-bookings" element={<MyClasses />} />
          <Route path="/class-content/:batchId" element={<ClassContent />} />
          <Route path="/class-content/video/:itemId" element={<VideoViewer />} />
          <Route path="/tc-for-student" element={<TcStudent />} />
          <Route path="/tc-for-teacher" element={<TcTutors />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/cookie-setting" element={<CookieSetting />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/verify" element={<EmailVerification />} />
          {/* <Route
            path="/student-info"
            element={
              <Layout>
                <StudentInfo />
              </Layout>
            }
          />
          <Route
            path="/badges"
            element={
              <Layout>
                <Badges />
              </Layout>
            }
          />
          <Route
            path="/dashboard"
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />
          <Route path="/post" element={<Layout><Post /></Layout>} />
          <Route
            path="/reviews"
            element={
              <Layout>
                <Reviews />
              </Layout>
            }
          />
          <Route
            path="/profile"
            element={
              <Layout>
                <Profile />
              </Layout>
            }
          /> */}
          <Route path="*" element={<Home />} />
        </Routes>
      ) : (
        (window.location.href = "/")
      )}
    </>
  );
};

export default App;
